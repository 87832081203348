define("discourse/plugins/discourse-slack-official/discourse/models/filter-rule", ["exports", "discourse/models/rest", "discourse/models/category", "ember-addons/ember-computed-decorators"], function (_exports, _rest, _category, _emberComputedDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _rest.default.extend((_dec = (0, _emberComputedDecorators.default)('category_id'), _dec2 = (0, _emberComputedDecorators.default)('filter'), (_obj = {
    category_id: null,
    channel: '',
    filter: null,
    categoryName(categoryId) {
      if (!categoryId) {
        return I18n.t('slack.choose.all_categories');
      }
      const category = _category.default.findById(categoryId);
      if (!category) {
        return I18n.t('slack.choose.deleted_category');
      }
      return category.get('name');
    },
    filterName(filter) {
      return I18n.t(`slack.present.${filter}`);
    }
  }, (_applyDecoratedDescriptor(_obj, "categoryName", [_dec], Object.getOwnPropertyDescriptor(_obj, "categoryName"), _obj), _applyDecoratedDescriptor(_obj, "filterName", [_dec2], Object.getOwnPropertyDescriptor(_obj, "filterName"), _obj)), _obj)));
});