define("discourse/plugins/discourse-slack-official/discourse/routes/admin-plugins-slack", ["exports", "discourse/plugins/discourse-slack-official/discourse/models/filter-rule", "discourse/lib/ajax"], function (_exports, _filterRule, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Discourse.Route.extend({
    model() {
      return (0, _ajax.ajax)("/slack/list.json").then(result => {
        return result.slack.map(v => _filterRule.default.create(v));
      });
    }
  });
});