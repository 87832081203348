define("discourse/plugins/discourse-slack-official/discourse/controllers/admin-plugins-slack", ["exports", "discourse/plugins/discourse-slack-official/discourse/models/filter-rule", "discourse/lib/ajax", "discourse/lib/ajax-error", "ember-addons/ember-computed-decorators"], function (_exports, _filterRule, _ajax, _ajaxError, _emberComputedDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = Ember.Controller.extend((_dec = (0, _emberComputedDecorators.default)('editing.channel'), (_obj = {
    filters: [{
      id: 'watch',
      name: I18n.t('slack.future.watch'),
      icon: 'exclamation-circle'
    }, {
      id: 'follow',
      name: I18n.t('slack.future.follow'),
      icon: 'circle'
    }, {
      id: 'mute',
      name: I18n.t('slack.future.mute'),
      icon: 'times-circle'
    }],
    editing: _filterRule.default.create({}),
    saveDisabled(channel) {
      return Ember.isEmpty(channel);
    },
    actions: {
      // TODO: Properly implement logic on the backend
      // edit(rule) {
      //   this.set(
      //     'editing',
      //     FilterRule.create(rule.getProperties('filter', 'category_id', 'channel', 'tags'))
      //   );
      // },

      save() {
        const rule = this.get('editing');
        (0, _ajax.ajax)("/slack/list.json", {
          method: 'PUT',
          data: rule.getProperties('filter', 'category_id', 'channel', 'tags')
        }).then(() => {
          const model = this.get('model');
          const obj = model.find(x => x.get('category_id') === rule.get('category_id') && x.get('channel') === rule.get('channel') && x.get('tags') === rule.get('tags'));
          if (obj) {
            obj.setProperties({
              channel: rule.channel,
              filter: rule.filter,
              tags: rule.tags
            });
          } else {
            model.pushObject(_filterRule.default.create(rule.getProperties('filter', 'category_id', 'channel', 'tags')));
          }
        }).catch(_ajaxError.popupAjaxError);
      },
      delete(rule) {
        const model = this.get('model');
        (0, _ajax.ajax)("/slack/list.json", {
          method: 'DELETE',
          data: rule.getProperties('filter', 'category_id', 'channel', 'tags')
        }).then(() => {
          const obj = model.find(x => x.get('category_id') === rule.get('category_id') && x.get('channel') === rule.get('channel') && x.get('tags') === rule.get('tags'));
          model.removeObject(obj);
        }).catch(_ajaxError.popupAjaxError);
      },
      testNotification() {
        this.set('testingNotification', true);
        (0, _ajax.ajax)("/slack/test.json", {
          method: 'PUT'
        }).catch(_ajaxError.popupAjaxError).finally(() => {
          this.set('testingNotification', false);
        });
      },
      resetSettings() {
        (0, _ajax.ajax)("/slack/reset_settings.json", {
          method: 'PUT'
        }).catch(_ajaxError.popupAjaxError);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "saveDisabled", [_dec], Object.getOwnPropertyDescriptor(_obj, "saveDisabled"), _obj)), _obj)));
});